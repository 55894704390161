<template>
    <div>
        {{ $t('general.privacy-policy') }}
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy",
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
